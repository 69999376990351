<template>
  <div v-if="showSchedule">
    <template v-if="step < 3">
      <div class="head mb-4">
        <h3 class="mb-0">
          {{ t('paymentScheduler') }}
        </h3>
      </div>

      <StepperWrapper class="mb-4">
        <StepperStep
          :is-completed="step > 0"
          :is-last="!bookingPaymentSchedule?.SecurityDepositAmount && isCondensedPayment"
          show-content-on-completed
        >
          <template #title>
            <div class="title">
              <div>{{ t('bookingConfirmation') }}</div>
              <div>
                {{ formatPrice({ value: bookingPaymentSchedule?.DepositAmount ?? 0 }) }}
              </div>
            </div>
          </template>

          {{ step === 0
            ? t('dueToConfirm')
            : t('paid')
          }}
        </StepperStep>

        <StepperStep
          v-if="!isCondensedPayment"
          :is-completed="step > 1"
          :is-last="!bookingPaymentSchedule?.SecurityDepositAmount"
          show-content-on-completed
        >
          <template #title>
            <div class="title">
              <div>{{ t('secondPayment') }}</div>
              <div>
                {{ formatPrice({ value: bookingPaymentSchedule?.SecondPaymentAmount ?? 0 }) }}
              </div>
            </div>
          </template>

          {{ step < 2
            ? t('autoCharge', {
              lastFour,
              dueDate: formatAsFullDateLong(dates.utc(bookingPaymentSchedule?.SecondPaymentDueDate)),
            })
            : t('paid')
          }}
        </StepperStep>

        <StepperStep
          v-if="bookingPaymentSchedule?.SecurityDepositAmount"
          is-last
          :is-completed="step > 2"
          show-content-on-completed
        >
          <template #title>
            <div class="title">
              <div>{{ t('refundableSecurityDeposit') }}</div>
              <div>
                {{ formatPrice({ value: bookingPaymentSchedule.SecurityDepositAmount }) }}
              </div>
            </div>
          </template>

          <span>
            {{ step > 2
              ? t('paid')
              : t('autoCharge', {
                lastFour,
                dueDate: formatAsShortDateLong(dates.utc(financialSecurityDepositOn)),
              })
            }}
          </span>
        </StepperStep>
      </StepperWrapper>

      <ZTipbox variant="highlight">
        <I18nT
          keypath="clickHere"
          tag="span"
          class="chat-links"
         
        >
          <template #cta>
            <NuxtLink
             
              :to="localePath({
                name: 'dashboard-bookings-id-update-payment',
                params: { id: bookingId },
              })"
            >{{ t('updateMethod') }}
            </NuxtLink>
          </template>
        </I18nT>
      </ZTipbox>
    </template>
    <template v-else>
      <ZTable>
        <ZTableLineItem
          :label="t('booking.paid.toReserve')"
          :value="formatPrice({ value: paidToReserveAmount })"
          value-data-testid="payment-schedule-paid-reserve"
        />

        <ZTableLineItem
          v-if="bookingStage === 'DepositTaken' && financialCreditsToBeAppliedLater"
          :label="t('booking.availableCredit')"
          :value="formatPrice({ value: financialCreditsToBeAppliedLater })"
        />

        <ZTableLineItem
          v-if="financialPayLater"
          :value="formatPrice({ value: financialPayLater })"
          value-data-testid="payment-schedule-pay-later"
        >
          <template #label>
            {{ t(isBeforeFullPaymentReceived ? 'booking.pay.on' : 'booking.paid.on') }}
            {{ formatAsShortDateLong(dates.utc(financialPayLaterOn)) }}
          </template>
        </ZTableLineItem>

        <ZTableLineItem
          v-if="financialSecurityDepositTaken"
          :value="formatPrice({ value: financialSecurityDepositAmount })"
        >
          <template #label>
            {{ t('booking.securityDeposit.takenOn') }}
            {{ formatAsShortDateLong(dates.utc(financialSecurityDepositTaken)) }}
          </template>
        </ZTableLineItem>

        <ZTableLineItem
          v-else-if="financialSecurityDepositFailed"
          :value="formatPrice({ value: financialSecurityDepositAmount })"
        >
          <template #label>
            {{ t('booking.securityDeposit.failedOn') }}
            {{ formatAsShortDateLong(dates.utc(financialSecurityDepositFailed)) }}
          </template>
        </ZTableLineItem>

        <ZTableLineItem
          v-else-if="financialSecurityDepositOn"
          :value="formatPrice({ value: financialSecurityDepositAmount })"
        >
          <template #label>
            {{ t('booking.securityDeposit.on') }}
            {{ formatAsShortDateLong(dates.utc(financialSecurityDepositOn)) }}
          </template>
        </ZTableLineItem>
      </ZTable>
    </template>
  </div>
</template>

<script setup lang="ts">
type BookingPaymentStates = 'awaitingDepositTaken' | 'awaitingFullPayment' | 'awaitingSecurityDeposit' | 'fullPaymentFailed' | 'fullPaymentTaken' | 'intialDepositTaken' | 'securityDepositFailed' | 'securityDepositTaken'
type Step = 0 | 1 | 2 | 3

const {
  bookingCountry,
  bookingFirstPaymentDate,
  bookingId,
  bookingNoPaymentMethod,
  bookingPaymentFailed,
  bookingPaymentMethod,
  bookingPaymentSchedule,
  bookingStage,
  bookingStageIsCanceled,
  bookingStageIsDeclined,
  bookingStageIsExpired,

  financialCreditsApplied,
  financialCreditsToBeAppliedLater,
  financialDepositAmount,
  financialPayLater,
  financialPayLaterOn,
  financialPayToReserve,
  financialPayments,
  financialSecurityDepositOn,
  financialSecurityDepositAmount,
  financialSecurityDepositTaken,
  financialSecurityDepositFailed,
} = useBookingDetails()

const { t } = useI18n()
const localePath = useLocalePath()

const { priceDisplay } = usePrice()
function formatPrice({ value }: { value: number }) {
  return priceDisplay({ value, countryCode: bookingCountry.value })
}

const showSchedule = computed(() => {
  return !bookingStageIsDeclined.value
    && !bookingStageIsCanceled.value
    && !bookingStageIsExpired.value
    && bookingStage.value !== 'EnquiryRequest'
})

const currentPaymentState = computed((): BookingPaymentStates => {
  const paidWithAffirm = financialPayments.value.some((payment) => payment.PaymentMethodType === 'Affirm')
  if (
    (bookingNoPaymentMethod.value && !paidWithAffirm && financialCreditsApplied.value < financialPayToReserve.value)
    || bookingFirstPaymentDate === null
    || (bookingStage.value === 'AwaitingPayment' && bookingPaymentFailed.value)
  ) {
    return 'awaitingDepositTaken'
  }

  if (bookingStage.value === 'DepositTaken') {
    return 'intialDepositTaken'
  }

  if (
    financialPayLater.value
    && (bookingStage.value === 'Invoice'
      || bookingStage.value === 'AwaitingPayment'
      || bookingStage.value === 'AwaitingOwnerApproval'
    )
  ) {
    return 'awaitingFullPayment'
  }

  if (bookingStage.value === 'FullPaymentReceived' && !bookingPaymentFailed.value) {
    return 'fullPaymentTaken'
  }

  if (bookingPaymentFailed.value) {
    return 'fullPaymentFailed'
  }

  if (financialSecurityDepositFailed.value) {
    return 'securityDepositFailed'
  }

  if (!financialSecurityDepositTaken.value) {
    return 'awaitingSecurityDeposit'
  }

  return 'securityDepositTaken'
})

const stepMapping: Record<BookingPaymentStates, Step> = {
  awaitingDepositTaken: 0,
  intialDepositTaken: 1,
  awaitingFullPayment: 1,
  fullPaymentFailed: 1,
  fullPaymentTaken: 2,
  awaitingSecurityDeposit: 2,
  securityDepositFailed: 2,
  securityDepositTaken: 3,
}

const step = computed(() => {
  return stepMapping[currentPaymentState.value]
})

const isBeforeFullPaymentReceived = computed(() => {
  return bookingStage.value === 'Invoice'
    || bookingStage.value === 'AwaitingPayment'
    || bookingStage.value === 'AwaitingOwnerApproval'
    || bookingStage.value === 'DepositTaken'
})

const isCondensedPayment = computed(() => bookingPaymentSchedule.value?.SecondPaymentAmount === 0)
const lastFour = computed(() => bookingPaymentMethod.value?.Last4)

const paidToReserveAmount = computed(() => {
  return Math.max(financialDepositAmount.value - financialCreditsApplied.value, 0)
})
</script>

<style lang="scss" scoped>
.head {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.step {
  .title {
    @include strong-1;
    color: getColor('primary-500');
    margin-bottom: 0.25rem;
    display: flex;
    justify-content: space-between;
  }

  :deep(.content-wrapper) {
    padding-bottom: 1.5rem;
  }
}
</style>

<i18n src="~/locales/common/booking/security-deposit.json" lang="json" />

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "paymentScheduler": "Payment schedule",
    "bookingConfirmation": "Booking confirmation",
    "dueToConfirm": "Payment due to confirm booking",
    "paid": "Paid",
    "refundableSecurityDeposit": "Refundable security deposit",
    "secondPayment": "Second payment",
    "autoCharge": "Will be charged automatically to your card ****{lastFour} on {dueDate}",
    "updateMethod": "update your payment method",
    "clickHere": "Click here if you would like to {cta}",
    "booking": {
      "paid": {
        "toReserve": "Paid to reserve",
        "on": "Paid on"
      }
    }
  },
  "fr": {
    "paymentScheduler": "Calendrier de paiement",
    "bookingConfirmation": "Confirmation de réservation",
    "dueToConfirm": "Le paiement est dû pour confirmer la réservation",
    "paid": "Payé",
    "refundableSecurityDeposit": "Dépôt de garantie remboursable",
    "secondPayment": "Deuxième paiement",
    "autoCharge": "Sera débité automatiquement de votre carte ****{lastFour} le {dueDate}",
    "updateMethod": "mettre à jour votre méthode de paiement",
    "clickHere": "Cliquez ici si vous souhaitez {cta}",
    "booking": {
      "paid": {
        "toReserve": "Payé pour réserver",
        "on": "Payé le"
      }
    }
  }
}
</i18n>
