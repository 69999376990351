<template>
  <ZTable
    v-bind="$attrs"
    :size="size"
  >
    <!-- Rental amount -->
    <ZTableLineItem
      :discount="highlightLine === 'dates'"
      label-data-testid="rental-total-nights"
      :label="t('booking.rentalXnights', financialTotalNights)"
      :value="formatPrice({ value: financialTotalPreDiscountRentalAmount })"
      value-data-testid="rental-nights-cost"
    />

    <!-- Discounts -->
    <ZTableLineItem
      v-for="discount in discounts"
      :key="discount.type"
      :label="discount.label"
      :value="formatPrice({ value: discount.amount })"
      :value-data-testid="`${discount.type}-discount-amount`"
      :discount="highlightLine === 'discount'"
    />

    <!-- Addons -->
    <BookingPriceBreakdownReceiptAddOns
      :financial="financial"
      :highlight="highlightLine === 'addons'"
    />

    <!-- Cleaning fee -->
    <ZTableLineItem
      v-if="financialTotalCleanings"
      :label="t('booking.cleaningFee.text')"
      :value="formatPrice({ value: financialTotalCleanings })"
      value-data-testid="cleaning-fee-addon"
    />

    <!-- Mileage charge -->
    <ZTableLineItem
      v-if="rvIsMotorized"
      :label="milageChargeLabel"
      :tooltip="milageChargeTooltip"
      :value="milageChargeValue"
    />

    <!-- Power generator charge -->
    <ZTableLineItem
      v-if="rvSnapshotIsChargeGenerator"
      :label="powerGeneratorLabel"
      :tooltip="powerGeneratorTooltip"
      :value="powerGeneratorValue"
    />

    <!-- Protection -->
    <ZTableLineItem
      v-if="financialInsuranceProtectionLevel"
      :label="t(`booking.protection.protections.${financialInsuranceProtectionLevel}.protection`)"
      :value="formatPrice({ value: insuranceFee })"
      value-data-testid="total-insurance-cost"
    />
    <ZTableLineItem
      v-else-if="hasRoadsideAddon"
      :label="t('booking.roadsideAssistance.text')"
      :value="formatPrice({ value: financialTotalRoadsides })"
      value-data-testid="total-roadside-cost"
    />

    <!-- Delivery -->
    <ZTableLineItem
      v-if="showTotalDelivery"
      :label="t('booking.delivery')"
      :value="formatPrice({ value: financialTotalDelivery })"
      value-data-testid="total-delivery-cost"
    />

    <!-- Taxes and fees -->
    <ZTableLineItem
      is-clickable
      :value="showTaxesAndFees ? undefined : formatPrice({ value: taxesAndFeesTotal })"
      @click="toggleExpandFees"
    >
      <template #label>
        {{ t('booking.taxesAndFees') }}
        <ZToggleArrow
          :toggled="showTaxesAndFees"
          class="text-highlight"
        />
      </template>
    </ZTableLineItem>

    <!-- Fees -->
    <ZTableLineItem
      v-show="showTaxesAndFees"
      :label="t('booking.RVezyFee.text')"
      :value="formatPrice({ value: financialTotalRenterServiceFee })"
      value-data-testid="servicefeecharges"
      :nested="1"
    />

    <!-- Taxes -->
    <template v-if="shouldCombineTaxes">
      <ZTableLineItem
        v-if="financialTotalRenterTaxes"
        v-show="showTaxesAndFees"
        :label="t('booking.tax')"
        :tooltip="t('booking.taxAdvise')"
        :value="formatPrice({ value: financialTotalRenterTaxes })"
        :value-data-testid="`provincialTax-${financialTotalRenterTaxes}`"
        :nested="1"
      />
    </template>
    <template v-else>
      <ZTableLineItem
        v-for="tax in financialRenterTaxAmounts"
        v-show="showTaxesAndFees"
        :key="tax.TaxCode ?? tax.Id"
        :label="t('booking.RVezyFee.withTax', { tax: tax.TaxCode })"
        :label-data-testid="`tax-${tax.TaxCode}`"
        :value="formatPrice({ value: tax.Amount })"
        :value-data-testid="`taxCharged-${tax.TaxCode}`"
        :nested="1"
       
      />
    </template>

    <!-- Sales tax -->
    <template v-if="financialTotalOwnerSalesTaxes">
      <ZTableLineItem
        v-for="tax in financialOwnerSalesTaxAmounts"
        v-show="showTaxesAndFees"
        :key="tax.TaxCode ?? tax.Id"
        :label="t('booking.ownerSalesTaxesWithCode', { code: tax.TaxCode })"
        :label-data-testid="`ownersalesTax-${tax.TaxCode}`"
        :value="formatPrice({ value: tax.Amount })"
        :value-data-testid="`ownersalestaxCharged-${tax.TaxCode}`"
        :nested="1"
      />
    </template>

    <template v-if="financialTotalUSTaxes">
      <ZTableLineItem
        v-for="tax in financialUSTaxAmounts"
        v-show="showTaxesAndFees"
        :key="tax.TaxCode ?? tax.Id"
        :label="t('booking.salesTaxWithCode', { code: tax.TaxCode })"
        :value="formatPrice({ value: tax.Amount })"
        :nested="1"
      />
    </template>

    <slot name="prepend-total" />

    <ZTableLineItem
      v-if="!hideTotal && financialTotalCloseouts > 0"
      :label="t('additionalCharges')"
      :value="formatPrice({
        value: totalCloseoutChargesAmount,
      })"
      value-data-testid="renter-total-closeout-charges"
    />

    <!-- Total -->
    <ZTableLineItem
      v-if="!hideTotal"
      :label="t('booking.total')"
      :value="formatPrice({ value: renterTotal, showCurrencyCode: true })"
      value-data-testid="renter-total-cost"
      total
    />

    <slot name="append-total" />
  </ZTable>

  <template v-if="!hideReceiptFooter">
    <hr class="my-3">

    <BookingPriceBreakdownReceiptRenterFooter :financial="financial" />
  </template>
</template>

<script setup lang="ts">
// This component can be potentially consolidated with EstimateReceipt

import type { highlightedLine } from '~/components/booking/price-breakdown/receipt/types'
import type { FinancialCommon, Nullable, RvSnapshot } from '~/types'
import type { TableSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  financial: Nullable<FinancialCommon>
  rvSnapshot: Nullable<RvSnapshot>
  highlightLine?: highlightedLine
  hideTotal?: boolean
  deliveryDistance?: number
  excludeDiscountFromTotal?: boolean
  size?: TableSize
  hideReceiptFooter?: boolean
}>(), {
  highlightLine: 'discount',
})

const {
  financialDiscountsMidweekdays,
  financialDiscountsMonthly,
  financialDiscountsWeekly,
  financialInsuranceProtectionLevel,
  financialOwnerSalesTaxAmounts,
  financialRenterTaxAmounts,
  financialRenterTotal,
  financialTotalCleanings,
  financialTotalCloseouts,
  financialTotalDamages,
  financialTotalDelivery,
  financialTotalInsurances,
  financialTotalNights,
  financialTotalOwnerSalesTaxes,
  financialTotalPreDiscountRentalAmount,
  financialTotalPromotionalDiscountAmount,
  financialTotalRenterServiceFee,
  financialTotalRenterTaxes,
  financialTotalRoadsides,
  financialTotalUSTaxes,
  financialUSTaxAmounts,
} = useFinancialData(computed(() => props.financial))

const {
  rvSnapshotChargePerHourOver,
  rvSnapshotCountry,
  rvSnapshotDailyKMAllowed,
  rvSnapshotFreeHoursPerDay,
  rvSnapshotIsChargeGenerator,
  rvSnapshotIsChargeGeneratorEnabled,
  rvSnapshotIsChargeMileageWithSomeIncluded,
  rvSnapshotSurchargePerExtraKM,
} = useRvSnapshot(computed(() => props.rvSnapshot))

const { rvCountry, rvIsMotorized } = useRvDetails()

const { t } = useI18n()

const { priceDisplay } = usePrice()
function formatPrice({ value, showCurrencyCode }: { value: number, showCurrencyCode?: boolean }) {
  return priceDisplay({ value, countryCode: rvCountry.value, showCurrencyCode })
}

const discounts = computed(() => {
  const discounts = []

  // Midweek discount
  if (financialDiscountsMidweekdays.value) {
    discounts.push({
      type: 'midweek',
      label: t('booking.midweekDiscount', financialDiscountsMidweekdays.value.NumberOfNights),
      amount: toNegativeNumber(financialDiscountsMidweekdays.value.DiscountAmount),
    })
  }

  // Range discount
  if (financialDiscountsMonthly.value) {
    discounts.push({
      type: 'monthly',
      label: t('booking.monthlyDiscount'),
      amount: toNegativeNumber(financialDiscountsMonthly.value.DiscountAmount),
    })
  }
  if (financialDiscountsWeekly.value) {
    discounts.push({
      type: 'weekly',
      label: t('booking.weeklyDiscount'),
      amount: toNegativeNumber(financialDiscountsWeekly.value.DiscountAmount),
    })
  }

  return discounts
})

const hasRoadsideAddon = computed(() => {
  return Boolean(financialTotalRoadsides.value)
})

const insuranceFee = computed(() => {
  if (financialInsuranceProtectionLevel.value) {
    return financialTotalInsurances.value + financialTotalRoadsides.value
  }

  return financialTotalInsurances.value
})

const showTotalDelivery = computed(() => {
  return financialTotalDelivery.value || props.deliveryDistance
})

const milageChargeLabel = computed(() => {
  if (!rvSnapshotIsChargeMileageWithSomeIncluded.value) {
    return t('booking.mileage.text')
  }
  return t(`booking.mileage.${rvCountry.value}.text`, {
    dailyKmAllowed: rvSnapshotDailyKMAllowed.value,
  })
})

const milageChargeTooltip = computed(() => {
  if (!rvSnapshotIsChargeMileageWithSomeIncluded.value) {
    return undefined
  }
  return t(`booking.mileage.${rvCountry.value}.tooltip`, {
    kms: rvSnapshotDailyKMAllowed.value,
    cost: formatPrice({ value: rvSnapshotSurchargePerExtraKM.value }),
  })
})

const milageChargeValue = computed(() => {
  return t(rvSnapshotIsChargeMileageWithSomeIncluded.value ? 'included' : 'unlimited')
})

const powerGeneratorLabel = computed(() => {
  if (!rvSnapshotIsChargeGeneratorEnabled.value) {
    return t('booking.powerGenerator.text')
  }
  return t('booking.powerGenerator.chargeText', { time: rvSnapshotFreeHoursPerDay.value })
})

const powerGeneratorTooltip = computed(() => {
  if (!rvSnapshotIsChargeGeneratorEnabled.value) {
    return undefined
  }
  return t('booking.powerGenerator.tooltip', { time: rvSnapshotFreeHoursPerDay.value, cost: formatPrice({ value: rvSnapshotChargePerHourOver.value }) })
})

const powerGeneratorValue = computed(() => {
  return t(rvSnapshotIsChargeGeneratorEnabled.value ? 'included' : 'unlimited')
})

const showTaxesAndFees = ref(false)

function toggleExpandFees() {
  showTaxesAndFees.value = !showTaxesAndFees.value
}

const taxesAndFeesTotal = computed(() => {
  return (
    financialTotalRenterTaxes.value
    + financialTotalRenterServiceFee.value
    + financialTotalOwnerSalesTaxes.value
    + financialTotalUSTaxes.value
  )
})

const shouldCombineTaxes = computed(() => {
  // Only if the country is Canada
  if (rvSnapshotCountry.value !== 'CA') {
    return false
  }

  // Only if the province has provicial tax enabled
  // So if financialRenterTaxAmounts contains PST or QST
  return financialRenterTaxAmounts.value.some((tax) => ['PST', 'QST'].includes(tax?.TaxCode ?? ''))
})

const totalCloseoutChargesAmount = computed(() => {
  return financialTotalCloseouts.value + financialTotalDamages.value
})

const renterTotal = computed(() => {
  const discount = props.excludeDiscountFromTotal ? 0 : financialTotalPromotionalDiscountAmount.value
  return financialRenterTotal.value + financialTotalCloseouts.value + financialTotalDamages.value + discount
})
</script>

<i18n src="~/locales/common/booking/rvezy-fee.json" lang="json" />

<i18n src="~/locales/common/booking/discount.json" lang="json" />

<i18n src="~/locales/common/booking/mileage.json" lang="json" />

<i18n src="~/locales/common/booking/cleaning-fee.json" lang="json" />

<i18n src="~/locales/common/booking/protection.json" lang="json" />

<i18n src="~/locales/common/booking/tax.json" lang="json" />

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n lang="json">
  {
    "en": {
      "additionalCharges": "Additional Charges"
    },
    "fr": {
      "additionalCharges": "Frais supplémentaires"
    }
  }
</i18n>
